import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import translation from "../../shared/utils/translation";
import TitlePrimary from "../../shared/components/text/TitlePrimary";
import TextGrid from "../../shared/components/grid/TextGrid";
import {TitleSizes} from "../../shared/utils/vars";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  flex-direction: column;
`

const TitleWrapper = styled.div`
  text-align: start;
  margin-bottom: 15px;
`

const WhyUsSection = () => {
  const textObject = [
    {text: translation("why.paragraph.first"), textAbove: true},
    {text: [ translation("why.paragraph.second1"), translation("why.paragraph.second2")], textAbove: true},
    {text: translation("why.paragraph.third"), textAbove: true},
    {text: `${translation("why.strongword1")}, ${translation("why.strongword2")}`
      ,
      strongText: true,
      small: true,
      placement: "start",
      size: TitleSizes.md
    },
    {text: translation("why.paragraph.fourth"), textBelow: true},
    {text: translation( "why.paragraph.fifth.strong1"),
      strongText: true,
      placement: "start",
      size: TitleSizes.md,
      margin: "15px 0 5px 0"
    },
    {text: translation( "why.paragraph.fifth.strong2"),
      strongText: true,
      placement: "start",
      size: TitleSizes.md,
      margin: "0 0 15px 0"
    },
  ]

  return (
    <Wrapper>
      <TitleWrapper>
       <TitlePrimary small={true}>{translation("common.title.whyus")}</TitlePrimary>
      </TitleWrapper>
      <div>
        <TextGrid object={textObject}/>
      </div>

    </Wrapper>
  )
}

export default observer(WhyUsSection)