import {action} from "mobx"
import Cookie from "js-cookie";

export const setCookieLanguage = action((lang) => {
  if(!lang)throw new Error("No entered language")
  Cookie.set("selectedLanguage", lang)
})

export const setCookieProductData = action((productId, pathId) => {
  if(productId === undefined)throw new Error("No entered ProductID")
  if(pathId === undefined)throw new Error("No entered PathID")
  Cookie.set("selectedProductId", productId)
  Cookie.set("selectedProductPath", pathId)

})
