import MainApi from "./MainApi";

export const BASE_URL = "main-api/api"

const ApiGateway = {
  products: {
    getCategory: async () => {
      const data = await MainApi(`${BASE_URL}/products/get-category`)
      return data
    },
    getSubCategory: async (id) => {
      const  data  = await MainApi(`${BASE_URL}/products/get-subcategory/${id}`)
      return data
    }
  },
  news: {
    getNews: async (config) => {
      const data = await MainApi(`${BASE_URL}/news/get-news`, config)
      return data
    },
    getTotal: async(config) => {
      const data = await MainApi(`${BASE_URL}/news/total`, config)
      return data.result
    },
    getTags: async() => {
      const data = await MainApi(`${BASE_URL}/news/get-tags`)
      return data.result
    },
  }
}
export default ApiGateway