export const API_URL = "https://www.studydeluxe.com/"

export default async function MainApi(path, config){
  const response = await fetch(`${API_URL}${path}`, config);
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const result = await response.json();
  return result;
}