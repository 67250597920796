import {makeAutoObservable, toJS} from "mobx";
import ApiGateway from "../api/ApiGateway";


class ProductStore {

  selectedCategory = null
  category = []
  subCategory = []

  constructor() {
    makeAutoObservable(this)
  }

  async loadCategory() {
    const data = await ApiGateway.products.getCategory();
    this.category = data.result ;
  }

  async loadSubCategory(id) {
    const data = await ApiGateway.products.getSubCategory(id);
    this.subCategory = data.result;
  }

  async getCategory(id){
   return await toJS(this.category).find(value => value.id === parseInt(id));
  }

  getProductsImage() {

  }

}

export default new ProductStore()