import {
  selectedLanguage as configStoreSelectedLanguage,
  defaultLanguage as configStoreDefaultLanguage
} from "../stores/ConfigStore";
import {languages} from "../locales/languages";

export default function translation(key) {
  let selectedLanguage = configStoreSelectedLanguage.get() ? configStoreSelectedLanguage.get() : configStoreDefaultLanguage.get();
  let text = languages[selectedLanguage][key] || key;

  // Regex to find the placeholders for links in the format {{link:URL|link text}}
  const linkRegex = /\{\{link:(https?:\/\/[^\|\}]+)\|([^\}]+)\}\}/g;

  // Split the text into parts and process links
  const parts = text.split(linkRegex).reduce((acc, part, index, array) => {
    // If part is a URL (which comes after an even index), create a link element
    if (index % 3 === 1) {
      acc.push(
          <a href={part} target="_blank" rel="noopener noreferrer" key={part}>
            {array[index + 1]}
          </a>
      );
    }
    // Push the regular text
    else if (index % 3 === 0) {
      acc.push(part);
    }
    return acc;
  }, []);
  return parts.length > 1 ? <>{parts}</> : text;

}
