import * as React from "react"
import { FaPlug } from 'react-icons/fa';
import styled from "styled-components";
const PowerSupply = styled(FaPlug)`
  font-size: ${p => p.small ? 18 : 26}px;
  font-weight: ${p => p.bold ? 900 : 500};
  cursor: pointer;
  margin: ${p => p.margin ? p.margin : 0};
  
  &:hover, &:focus{
    color: lightgray;
  }
`

const PowerSupplyIcon = ({small = false, margin, bold = false, onClick}) => {
  return (
    <PowerSupply small={small} bold={bold} margin={margin} onClick={onClick}/>
  )
}

export default PowerSupplyIcon
