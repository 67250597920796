import {
  selectedLanguage as ConfigStoreSelectedLanguage,
  setSelectedLanguage as ConfigStoreSetSelectedLanguage,
  hostname as ConfigStoreHostName,
  setHostname as SetConfigStoreHostName,
} from "../../stores/ConfigStore";
import Cookie from "js-cookie";

export default async function config(){
  let hostname = "";
  let language = "";

  if(document.domain !== ConfigStoreHostName.get()){
    if(document.domain === "localhost"){
      hostname = "toplednordic.com"
    } else {
      hostname = document.domain
    }
  } else {
    hostname = ConfigStoreHostName.get()
  }


  if((ConfigStoreSelectedLanguage.get() === "" || ConfigStoreSelectedLanguage.get() === undefined) && Cookie.get("selectedLanguage") === undefined){
    if(hostname.includes(".com")){
      language = "en"
    }
    if(hostname.includes(".se")){
      language = "se"
    }
  } else {
    language = ConfigStoreSelectedLanguage.get() === "" ? Cookie.get("selectedLanguage") : ConfigStoreSelectedLanguage.get();
  }

  ConfigStoreSetSelectedLanguage(language)
  SetConfigStoreHostName(hostname)
}