import React, {useEffect, useState} from "react"
import {observer} from "mobx-react-lite";
import styled from "styled-components"
import {sizes} from "../../shared/utils/media";
import ImageGrid from "../../shared/components/grid/ImageGrid";
import ProductStore from "../../shared/stores/ProductStore";
import {toJS} from "mobx";

const CategorySectionWrapper = styled.section`
  @media only screen and (max-width:${sizes.tablet}px){
    flex-direction: column;
  }
`

function CategorySection(){
  const [hasProducts, setHasProducts] = useState(false)

  useEffect(() => {
    if(toJS(ProductStore.category).length > 0){
      setHasProducts(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toJS(ProductStore.category)])

  return (
    <CategorySectionWrapper id="category">
      {hasProducts &&
        <ImageGrid object={toJS(ProductStore.category)} type={"category"} textTranslation={"category."} itemsPerRow={3} centerImage={true} centerText={true} smallText={false} wrapperStyle={{marginBottom: "20px"}} imageStyle={{width: "15em", height: "15em", objectFit: "cover", borderRadius: "50%"}} />
       }
    </CategorySectionWrapper>
  )
}

export default observer(CategorySection)