import React from "react"
import styled from "styled-components"
import {media} from "../utils/media";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";

const HomeLink = styled.div`
  cursor: pointer;
  img {
    display: block;
    max-height: ${p => p.position === "header" ? "4.2em" : "12.5em"};
    ${media.phone`
    max-height: ${p => p.position === "header" ? "45px" : "6.2em"};
    `};
  }
`

const Logo = ({position = "header", color = "white"}) => {
  const imageUrl = `/static/logo/logo-${color}.png`
  let navigate = useNavigate();

  const returnHome = () => {
    navigate('/')
  }

  return (
    <HomeLink onClick={returnHome} position={position}>
      <img alt="logo" src={imageUrl} />
    </HomeLink>
  )
}

export default observer(Logo)